<template>
    <div class="app">
        <b-navbar toggleable="lg" type="dark" variant="dark">
            <b-navbar-brand href="/home">
                {{portalName()}}
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item-dropdown v-if="security.hasSystems">
                    <template #button-content>
                        <font-awesome-icon
                            icon="network-wired"
                            class="nav-icon fa icon-width"
                        />
                        Systems
                    </template>
                    <b-dropdown-item v-if="security.userRead"
                        @click="pushRouterPath('/users')">
                        <font-awesome-icon
                            icon="user"
                            class="nav-icon fa icon-width"
                        />
                        Users
                    </b-dropdown-item>
                        <b-dropdown-item v-if="security.apiLoginRead" 
                            @click="pushRouterPath('/apilogins')">
                            <font-awesome-icon
                                icon="user-gear"
                                class="nav-icon fa icon-width"
                            />
                            API Logins
                        </b-dropdown-item>
                    <b-dropdown-item v-if="security.roleRead" 
                        @click="pushRouterPath('/roles')">
                        <font-awesome-icon
                            icon="filter"
                            class="nav-icon fa icon-width"
                        />
                        Roles
                    </b-dropdown-item>
                    <b-dropdown-item v-if="security.eventRead" 
                        @click="pushRouterPath('/events')">
                        <font-awesome-icon
                            icon="bell"
                            class="nav-icon fa icon-width"
                        />
                        Events
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.customFieldsRead"
                        @click="pushRouterPath('/customfields')"
                    >
                        <font-awesome-icon
                            icon="gear"
                            class="nav-icon fa icon-width"
                        />
                        Custom Fields
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.codeActivityRead"
                        @click="pushRouterPath('/codeactivity')"
                    >
                        <font-awesome-icon
                            icon="terminal"
                            class="nav-icon fa icon-width"
                        />
                        Code Activity
                    </b-dropdown-item>
                    <b-dropdown-item v-if="security.auditRead" 
                        @click="pushRouterPath('/audit')">
                        <font-awesome-icon
                            icon="magnifying-glass"
                            class="nav-icon fa icon-width"
                        />
                        Audit
                    </b-dropdown-item>
                    <b-dropdown-item v-if="security.portalConfigRead" 
                        @click="pushRouterPath('/portalconfig')">
                        <font-awesome-icon
                            icon="gears"
                            class="nav-icon fa icon-width"
                        />
                        Portal Configuration
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown v-if="security.hasFleet">
                    <template #button-content>
                        <font-awesome-icon
                            icon="boxes-stacked"
                            class="nav-icon fa icon-width"
                        />
                        Fleet
                    </template>
                    <b-dropdown-item 
                        v-if="security.kioskRead"
                        @click="pushRouterPath('/kiosks')">
                        <font-awesome-icon
                            icon="cash-register"
                            class="nav-icon fa icon-width"
                        />
                        Kiosks
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.servantRead"
                        @click="pushRouterPath('/servants')">
                        <font-awesome-icon
                            icon="robot"
                            class="nav-icon fa icon-width"
                        />
                        Servants
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.maintenanceLogsRead"
                        @click="pushRouterPath('/maintenancelogs')">
                        <font-awesome-icon
                            icon="wrench"
                            class="nav-icon fa icon-width"
                        />
                        Maintenance Logs
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.problemsRead"
                        @click="pushRouterPath('/problems')">
                        <font-awesome-icon
                            icon="circle-exclamation"
                            class="nav-icon fa icon-width"
                        />
                        Problems
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown v-if="security.hasBusiness">
                     <template #button-content>
                        <font-awesome-icon
                            icon="landmark"
                            class="nav-icon fa icon-width"
                        />
                        Business
                    </template>
                    <b-dropdown-item v-if="security.storeRead" 
                        @click="pushRouterPath('/stores')">
                        <font-awesome-icon
                            icon="store"
                            class="nav-icon fa icon-width"
                        />
                        Stores
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.transactionRead"
                        @click="pushRouterPath('/transactions')">
                        <font-awesome-icon
                            icon="money-bills"
                            class="nav-icon fa icon-width"
                        />
                        Transactions
                    </b-dropdown-item>
                    <b-dropdown-item v-if="security.refundRead" 
                        @click="pushRouterPath('/refunds')">
                        <font-awesome-icon
                            icon="money-bill-transfer"
                            class="nav-icon fa icon-width"
                        />
                        Refunds
                    </b-dropdown-item>
                    <b-dropdown-item v-if="security.productRead" 
                        @click="pushRouterPath('/products')">
                        <font-awesome-icon
                            icon="boxes-stacked"
                            class="nav-icon fa icon-width"
                        />
                        Products
                    </b-dropdown-item>
                    <b-dropdown-item v-if="security.productRead" 
                        @click="pushRouterPath('/prices')">
                        <font-awesome-icon
                            icon="coins"
                            class="nav-icon fa icon-width"
                        />
                        Prices
                    </b-dropdown-item>
                    <b-dropdown-item v-if="security.regionsRead" 
                        @click="pushRouterPath('/regions')">
                        <font-awesome-icon
                            icon="earth-americas"
                            class="nav-icon fa icon-width"
                        />
                        Regions
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown v-if="security.hasPromos">
                    <template #button-content>
                        <font-awesome-icon
                            icon="dollar-sign"
                            class="nav-icon fa icon-width"
                        />
                        Promo
                    </template>
                    <b-dropdown-item
                        v-if="security.hasPromos"
                        @click="pushRouterPath('/multipleuse')">
                        <font-awesome-icon
                            icon="check-double"
                            class="nav-icon fa icon-width"
                        />
                        Multiple Use
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.hasPromos"
                        @click="pushRouterPath('/singleuse')">
                        <font-awesome-icon
                            icon="check"
                            class="nav-icon fa icon-width"
                        />
                        Single Use
                    </b-dropdown-item>
                    <b-dropdown-item v-if="security.hasPromos" 
                        @click="pushRouterPath('/freeitem')">
                        <font-awesome-icon
                            icon="face-smile"
                            class="nav-icon fa icon-width"
                        />
                        Free Item
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.hasPromos"
                        @click="pushRouterPath('/transactioncodes')">
                        <font-awesome-icon
                            icon="hashtag"
                            class="nav-icon fa icon-width"
                        />
                        Transaction Codes
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.hasPromos"
                        @click="pushRouterPath('/reimbursement')">
                        <font-awesome-icon
                            icon="hand-holding-dollar"
                            class="nav-icon fa icon-width"
                        />
                        Reimbursement
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.hasPromos"
                        @click="pushRouterPath('/promoprogram')">
                        <font-awesome-icon
                            icon="desktop"
                            class="nav-icon fa icon-width"
                        />
                        Programs
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown v-if="security.hasReports">
                    <template #button-content>
                        <font-awesome-icon
                            icon="chart-pie"
                            class="nav-icon fa icon-width"
                        />
                        Reports
                    </template>
                    <b-dropdown-item
                        v-if="security.uptimeRead"
                        @click="pushRouterPath('/kioskUptime')">
                        <font-awesome-icon
                            icon="hourglass"
                            class="nav-icon fa icon-width"
                        />
                        Kiosk Uptime
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.uptimeRead"
                        @click="pushRouterPath('/regionUptime')">
                        <font-awesome-icon
                            icon="globe"
                            class="nav-icon fa icon-width"
                        />
                        Region Uptime
                    </b-dropdown-item>
                    <b-dropdown-item v-if="security.saleRead" 
                        @click="pushRouterPath('/sales')">
                        <font-awesome-icon
                            icon="receipt"
                            class="nav-icon fa icon-width"
                        />
                        Sales
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.commissionReportRead"
                        @click="pushRouterPath('/commission')">
                        <font-awesome-icon
                            icon="balance-scale"
                            class="nav-icon fa icon-width"
                        />
                        Commission
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.fileArchiveRead"
                        @click="pushRouterPath('/fileArchiveData')">
                        <font-awesome-icon
                            icon="file"
                            class="nav-icon fa icon-width"
                        />
                        File Archive Data
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.firmwareRead"
                        @click="pushRouterPath('/firmware')">
                        <font-awesome-icon
                            icon="upload"
                            class="nav-icon fa icon-width"
                        />
                        Firmware
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown v-if="security.hasFirmware">
                    <template #button-content>
                        <font-awesome-icon
                            icon="display"
                            class="nav-icon fa icon-width"
                        />
                        Firmware
                    </template>
                    <b-dropdown-item
                        v-if="security.softwareRead"
                        @click="pushRouterPath('/software')">
                        <font-awesome-icon
                            icon="floppy-disk"
                            class="nav-icon fa icon-width"
                        />
                        Software
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="security.firmwareRead"
                        @click="pushRouterPath('/assignment')">
                        <font-awesome-icon
                            icon="equals"
                            class="nav-icon fa icon-width"
                        />
                        Assignment
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-item v-if="!currentUser">
                    <router-link to="/login" class="nav-link">
                        <p class="nav-text">Sign in</p>
                    </router-link>
                </b-nav-item>
                <b-nav-item-dropdown v-if="currentUser" right>
                    <!-- Using 'button-content' slot -->
                    <template #button-content>
                        <font-awesome-icon icon="user" class="nav-icon fa-2x" />
                    </template>
                    <b-dropdown-item
                        v-if="multiplePortals()"
                        href="#"
                        @click.prevent="changePortal"
                        :disabled="
                            security.requiresTwoFa && !security.hasTwoFaEnabled
                        "
                    >
                        Change Portal
                    </b-dropdown-item>
                    <b-dropdown-divider
                        v-if="multiplePortals()"
                    ></b-dropdown-divider>
                    <b-dropdown-item href="/profile"> Profile </b-dropdown-item>
                    <b-dropdown-item href="#" @click.prevent="logOut">
                        Sign Out
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <div class="main">
            <router-view></router-view>
        </div>
        <Footer />
        <b-modal id="portals-select" ref="portals-select" hide-footer>
            <template #modal-title> Portal Select </template>
            <div class="d-block text-center">
                <div class="mark-default">
                    <font-awesome-icon
                        icon="fa-home"
                        class="nav-icon mark-default-btn action-btn"
                    ></font-awesome-icon>
                </div>
                <b-table
                    striped
                    hover
                    :fields="portalFields"
                    :items="portals"
                    @row-clicked="selectPortal"
                    responsive="true"
                    stacked="sm"
                >
                </b-table>
            </div>
            <b-button
                class="mt-3"
                block
                @click="$bvModal.hide('portals-select')"
                >Close</b-button
            >
        </b-modal>
    <Vidle :duration="60" :loop="true" hidden @refresh="onRefresh" @idle="onIdle"/>
    </div>
</template>

<style lang="scss">
@import 'node_modules/vue-bootstrap-sidebar/src/scss/default-theme';
</style>

<script>
import $ from 'jquery';
import { eventBus } from './event_bus';
import Vidle from 'v-idle'

export default {
    name: 'app',
    components: {
        Vidle
    },
    metaInfo: {
        meta: [
            { name: 'version', content: process.env.PACKAGE_VERSION },
            { name: 'api_url', content: process.env.VUE_APP_API_URL },
        ],
    },
    data() {
        return {
            initialShow: this.$route.meta.hideNavMenu ? false : true,
            portalFields: [
                {
                    key: 'name',
                    label: 'Name',
                    tdClass: 'pointer',
                    sortable: false,
                },
            ],
            jwtRefresh: false
        };
    },
    mounted() {
        this.setMenuActive(this.$route.path);
        eventBus.$on('user_changed', () => {
            document.title = this.portalName();
        });
        eventBus.$on('route_changed', (path) => {
            setTimeout(() => this.setMenuActive(path), 50);
        });
        document.title = this.portalName();
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        portals() {
            return this.$store.state.auth.portals;
        },
        webcode() {
            return this.$store.state.auth.portal.name;
        },
        security() { 
            return this.$store.state.auth.security;
        }
    },
    methods: {
        loginRoute() {
            var webcode = localStorage.getItem('webcode');

            if (webcode && location.hostname.includes('localhost')) {
                return `/${webcode}/login`
            } else {
                return '/login';
            }
        },
        logOut() {
            var curUser = this.$store.state.auth.user;

            this.$store.dispatch('auth/logout')
            localStorage.removeItem('webcode')
            localStorage.removeItem('portal_configuration')
            document.title = this.portalName()
            if (curUser.is_azure_sso){
                this.$msal.logoutRedirect({
                    postLogoutRedirectUri: this.loginRoute()
                });
            } 
            else {
                this.$router.push(this.loginRoute())
            }
        },
        portalName() {
            let portalid = this.$route.query.portal_id;
            var portal = null;
            if (portalid) {
                portal = this.getPortalById(portalid);
            }
            if (!portal) {
                portal = this.$store.state.auth.portal;
            }
            return portal ? portal.name : 'SLEEGERS Kiosk Solutions';
        },
        portalChangeLocked() {
            let portalid = this.$route.query.portal_id;
            return portalid != null;
        },
        getPortalById(id) {
            var portals = this.$store.state.auth.portals;
            for (var i = 0; i < portals.length; ++i) {
                var portal = portals[i];
                if (portal.id == id) {
                    return portal;
                }
            }
            return null;
        },
        multiplePortals() {
            if (this.portalChangeLocked()) return false;
            var portals = this.$store.state.auth.portals;
            return portals && portals.length > 1;
        },
        changePortal() {
            this.$refs['portals-select'].show();
        },
        selectPortal(portal) {
            this.$store.dispatch('auth/setPortal', portal);
            document.title = this.portalName();
            this.$refs['portals-select'].hide();
        },
        async setMenuActive(path) {
            var listGroupItems = $('.list-group-item');
            $(listGroupItems).find('a').removeClass('active');

            for (var item of listGroupItems) {
                var anchors = $(item).find('a');
                for (var anchor of anchors) {
                    var href = $(anchor).attr(':href');
                    if (href == path) {
                        $(anchor).addClass('active');
                    }
                }
            }
        },
        pushRouterPath(path) {
            this.$router.push(path)
        },
        onRefresh(){
            const authSecurity = this.$store.state.auth.security
            const currentUser = this.$store.state.auth.user
            const now = Date.now()
            if (!this.jwtRefresh && currentUser && authSecurity.jwt.exp - 300 < now / 1000) {
                this.jwtRefresh = true;
                this.$store.dispatch("auth/refreshJwtToken").then(() => {
                    this.jwtRefresh = false;
                }, 
                () => {
                    this.jwtRefresh = false;
                    this.$router.push({ path: "/login", query: { redirect_to: this.$route.fullPath} })
                })
            }
        },
        onIdle(){
            const authSecurity = this.$store.state.auth.security
            const currentUser = this.$store.state.auth.user
            const now = Date.now()
            if (currentUser && authSecurity.jwt.exp < now / 1000) {
                this.$store.dispatch("auth/logout")
                this.$router.push({ path: "/login", query: { redirect_to: this.$route.fullPath } })
            }
        }
    },
};
</script>

<style>
body {
    padding: 1rem;
}
p {
    margin-top: 0;
    margin-bottom: 0;
}
.main {
    margin-top: 22px;
}
.default-theme {
    cursor: default !important;
}
.pointer {
    cursor: pointer;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
    background-color: #005cbf !important;
}
.icon-width {
    width: 1.5rem;
}

.navbar {
    border-radius: 0.25rem
}

.help-link {
    color: #007bff;
    display: inline-block;
}

.help-link:hover {
    color: #004da0;
    cursor: pointer;
}

.help-link:hover > .help-icon {
    color: #004da0;
    cursor: pointer;
}

.help-icon {
    color: #007bff;
    width: 1.5rem;
}

.help-icon:hover {
    color: #004da0;
    cursor: pointer;
}
</style>