import Vue from "vue"
import Router from "vue-router"
import store from "./modules"

import { auth } from './modules/auth.module'
import { Home, PageNotFound, AccessDenied } from "./views"
import { Login, EnableTwoFa, CreateAccount, PortalConfiguration } from "./views/auth"
import { Kiosks, Kiosk, Transactions, Refunds, CodeActivity, MaintenanceLogs, RestoreData, Products, Prices, Servants, ServantDetails } from "./views/kiosks"
import { RegionUptime, KioskUptime, Sales, Commission, FileArchiveData } from "./views/reports"
import { Events, Users, ApiLogins, Roles, Audit, Regions } from "./views/settings"
import { SingleUse, FreeItem, MultipleUse, Reimbursements, TransactionCodes, PromoProgram } from "./views/promo"
import { Profile } from "./views/account"
import { Software, Assignment, Release } from "./views/firmware"
import { Problems, Firmware } from "./views/fleet"
import { CustomFields } from "./views/customfields"
import { eventBus } from "./event_bus"
import { Stores } from "./views/stores"
import { BToast } from 'bootstrap-vue'

Vue.use(Router);

export let router = new Router({
    mode: "history",
    scrollBehavior() {
        window.scrollTo(0, 0);
    },
    routes: [
        {
            name: "pagenotfound",
            path: "*",
            component: PageNotFound,
            meta: {
                pageNotFound: true,
                allowAnonymous: true,
            },
        },
        {
            name: "accessdenied",
            path: "/accessdenied",
            component: AccessDenied,
        },
        {
            name: "home",
            path: "/home",
            component: Home,
        },
        {
            name: "root",
            path: "/",
            component: Home,
            meta: {
                noPath: true,
            },
        },
        {
            name: "login",
            path: "/login",
            component: Login,
            meta: {
                allowAnonymous: true,
            },
        },
        {
            name: "webcodelogin",
            path: "/:webcode/login",
            component: Login,
            meta: {
                allowAnonymous: true,
            },
        },
        {
            name: "kiosk",
            path: "/kiosk/",
            component: Kiosk,
            meta: {
                hideNavMenu: true,
                authorized: "kiosks-r",
            }
        },
        {
            name: "kiosks",
            path: "/kiosks",
            component: Kiosks,
            meta: {
                authorized: "kiosks-r"
            }
        },
        {
            name: "transactions",
            path: "/transactions",
            component: Transactions,
            props: {
                isHistory: false
            },
            meta: {
                authorized: "transactions-r"
            }
        },
        {
            name: "products",
            path: "/products",
            component: Products,
            meta: {
                authorized: "products-r"
            }
        },
        {
            name: "prices",
            path: "/prices",
            component: Prices,
            meta: {
                authorized: "prices-r"
            }
        },
        {
            name: "refunds",
            path: "/refunds",
            component: Refunds,
            meta: {
                authorized: "refunds-r"
            }
        },
        {
            name: "sales",
            path: "/sales",
            component: Sales,
            meta: {
                authorized: "sales-r"
            }
        },
        {
            name: "kioskuptime",
            path: "/kioskUptime",
            component: KioskUptime,
            meta: {
                authorized: "uptime-r"
            }
        },
        {
            name: "regionuptime",
            path: "/regionUptime",
            component: RegionUptime,
            meta: {
                authorized: "uptime-r"
            }
        },
        {
            name: "commission",
            path: "/commission",
            component: Commission,
            meta: {
                authorized: "commission_report-r"
            }
        },
        {
            name: "codeactivity",
            path: "/codeactivity",
            component: CodeActivity,
            meta: {
                authorized: "code_activity-r"
            }
        },
        {
            name: "maintenancelogs",
            path: "/maintenancelogs",
            component: MaintenanceLogs,
            meta: {
                authorized: "maintenance_logs-r"
            }
        },
        {
            name: "events",
            path: "/events",
            component: Events,
            meta: {
                authorized: "events-r"
            }
        },
        {
            name: "users",
            path: "/users",
            component: Users,
            meta: {
                authorized: "user-r"
            }
        },
        {
            name: "apilogins",
            path: "/apilogins",
            component: ApiLogins,
            meta: {
                authorized: "api_login-r"
            }
        },
        {
            name: "roles",
            path: "/roles",
            component: Roles,
            meta: {
                authorized: "role-r"
            }
        },
        {
            name: "software",
            path: "/software",
            component: Software,
            meta: {
                authorized: "software-r"
            }
        },
        {
            name: "assignment",
            path: "/assignment",
            component: Assignment
        },
        {
            name: "release",
            path: "/release/:id",
            component: Release,
            meta: {
                authorized: "phase-r"
            }
        },
        {
            name: "restoredata",
            path: "/restoreData",
            component: RestoreData
        },
        {
            name: "filearchivedata",
            path: "/fileArchiveData",
            component: FileArchiveData,
            meta: {
                authorized: "file_archive_data-r"
            }
        },
        {
            name: "enabletwofa",
            path: "/enabletwofa",
            component: EnableTwoFa,
            meta: {
                userWithoutTwoFaWhileRequired: true
            }
        },
        {
            name: "profile",
            path: "/profile",
            component: Profile,
        },
        {
            name: "problems",
            path: "/problems",
            component: Problems,
            meta: {
                authorized: "kiosks-r"
            }
        },
        {
            name: "firmware",
            path: "/firmware",
            component: Firmware,
            meta: {
                authorized: "firmware-r"
            }
        },
        {
            name: "singleuse",
            path: "/singleuse",
            component: SingleUse,
            meta: {
                authorized: "promo-r"
            }
        },
        {
            name: "freeitem",
            path: "/freeitem",
            component: FreeItem,
            meta: {
                authorized: "promo-r"
            }
        },
        {
            name: "multipleuse",
            path: "/multipleuse",
            component: MultipleUse,
            meta: {
                authorized: "promo-r"
            }
        },
        {
            name: "reimbursement",
            path: "/reimbursement",
            component: Reimbursements,
            meta: {
                authorized: "promo-r"
            }
        },
        {
            name: "transactioncodes",
            path: "/transactioncodes",
            component: TransactionCodes,
            meta: {
                authorized: "promo-r"
            }
        },
        {
            name: "promoprogram",
            path: "/promoprogram",
            component: PromoProgram,
            meta: {
                authorized: "promo-r"
            }
        },
        {
            name: "stores",
            path: "/stores/",
            component: Stores,
        },
        {
            name: "audit",
            path: "/audit",
            component: Audit,
            meta: {
                authorized: "audit-r"
            }
        },
        {
            name: "createaccount",
            path: "/createaccount/:tokenid",
            component: CreateAccount,
            meta: {
                allowAnonymous: true,
                createAccount: true
            }
        },
        {
            name: "servants",
            path: "/servants",
            component: Servants,
            meta: {
                authorized: "servant-r"
            }
        },
        {
            name: "servant",
            path: "/servant",
            component: ServantDetails,
            meta: {
                authorized: "servant-r"
            }
        },
        {
            name: "customfields",
            path: "/customfields",
            component: CustomFields,
            meta: {
                authorized: "custom_fields-r"
            }
        },
        {
            name: "regions",
            path: "/regions",
            component: Regions,
            meta: {
                authorized: "regions-r"
            }
        },
        {
            name: "portalconfig",
            path: "/portalconfig",
            component: PortalConfiguration,
            meta: {
                authorized: "portal_configuration-r"
            }
        }
    ]
});

router.beforeEach((to, from, next) => {

    if (to.hash !== ''){ //For SSO, to prevent router from killing popup redirect
        return next();
    }

    if (eventBus) {
        eventBus.$emit("route_changed", to.path)
    }

    const authSecurity = auth.state.security
    const currentUser = auth.state.user

    var now = Date.now()
    if (currentUser) {
        if (authSecurity.jwt.exp - 300 < now / 1000) {
            store.dispatch("auth/refreshJwtToken")
        }

        if (authSecurity.requiresTwoFa && !authSecurity.hasTwoFaEnabled
            && (to.matched.some((record) => (record.meta.userWithoutTwoFaWhileRequired == undefined || record.meta.userWithoutTwoFaWhileRequired == false)))) {
            let bootStrapToast = new BToast();
            bootStrapToast.$bvToast.toast(`Two factor authentication is a requirement of user's role`, {
                title: 'Error',
                variant: 'danger',
                solid: true,
            });

            return next({ path: "/enabletwofa" })
        }
        else if (authSecurity.jwt.exp < now / 1000) {
            store.dispatch("auth/logout")
            return next({ path: "/login", query: { redirect_to: to.fullPath ?? from.fullPath } })
        }
        else if (to.matched.some((record) => !(record.meta.authorized == undefined || authSecurity.hasAccess(record.meta.authorized)))) {
            return next({ path: "/accessdenied", query: { redirect_to: from.fullPath } })
        }
        else if (to.matched.some((record) => record.meta.noPath)) {
            return next({ path: "/home" })
        }
        else if (to.matched.some((record) => record.meta.pageNotFound)) {
            next()
        }
        else if (to.matched.some((record) => record.meta.createAccount)) {
            let bootStrapToast = new BToast();
            bootStrapToast.$bvToast.toast(`Create account invite redirected. A user is already logged in`, {
                title: 'Error',
                variant: 'danger',
                solid: true,
            });
            return next({ path: "/home" })
        }
        else if (to.matched.some((record) => record.meta.allowAnonymous)) {
            return next({ path: "/home" })
        }
        else {
            next()
        }
    }
    else {
        if (to.matched.some((record) => record.meta.noPath)) {
            return next({ path: "/login", query: { redirect_to: to.query.redirect_to ?? to.fullPath } })
        }
        else if (to.matched.some((record) => !record.meta.allowAnonymous)) {
            return next({ path: "/login", query: { redirect_to: to.query.redirect_to ?? to.fullPath } })
        }
        else {
            next()
        }
    }
});